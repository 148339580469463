import { useEffect, useCallback, useRef } from "react";
import { types } from "@vilocnv/allsetra-core";
import { useAppDispatch, useAppSelector } from "./index";
import { getObjectDataForMapThunk, updateObjectLocation } from "app/features";
import { SignalRService } from "app/data/services";
import { selectDrawerSelectedAccountId } from "app/data/selectors";

const useObjectLiveLocation = (isSelectOpen = false) => {
  const dispatch = useAppDispatch();
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const isSelectOpenRef = useRef(isSelectOpen);

  useEffect(() => {
    isSelectOpenRef.current = isSelectOpen;
  }, [isSelectOpen]);

  const updateLiveObject = useCallback(
    (event: any) => {
      if (
        event.eventName === types.BackendEventsEnum.ObjectLocationReportedEvent
      ) {
        if (!isSelectOpenRef.current) {
          dispatch(updateObjectLocation(event));
        }

        const objectId = window.localStorage.getItem("selectedObjectId");

        if (
          drawerSelectedAccountId &&
          objectId &&
          event.uniqueId === objectId
        ) {
          dispatch(
            getObjectDataForMapThunk({
              accountId: drawerSelectedAccountId || "",
              objectId: objectId,
            })
          );
        }
      }
    },
    [dispatch, drawerSelectedAccountId]
  );

  useEffect(() => {
    SignalRService.hubConnection?.on("EventRaised", updateLiveObject);

    return () => {
      SignalRService.hubConnection?.off("EventRaised");
    };
  }, [updateLiveObject]);
};

export default useObjectLiveLocation;
