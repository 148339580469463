import { styled, Box } from "@mui/material";

export const OverviewLeftContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "60px",
  overflowY: "scroll",
  msOverflowStyle: "none",
  scrollbarWidth: "none",

  "&::-webkit-scrollbar": { display: "none" },

  "& h5": {
    color: "var(--gray-900, #1D1F2B)",
    fontFamily: "Gilroy",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "115%",
  },

  "& .css-12trww6": {
    color: "var(--gray-500, #76828F)",
    fontFamily: "Gilroy",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "12px",
    letterSpacing: "0.33px",
    textTransform: "uppercase",
  },

  "& .css-1hnm6b6": {
    marginTop: "12px",
  },

  [theme.breakpoints.down("sm")]: {
    overflowY: "hidden",
    maxHeight: "none",
  },
}));

export const OverviewRightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "60px",

  "& h5": {
    color: "var(--gray-900, #1D1F2B)",
    fontFamily: "Gilroy",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "115%",
  },

  [theme.breakpoints.down("md")]: {
    gap: "20vh",
  },
}));
