import { FC, useState, useMemo, useEffect } from "react";
import { isEmpty } from "lodash";
import { Formik, FormikHelpers } from "formik";
import { FormikSelectField, Modal, toast, types } from "@vilocnv/allsetra-core";
import { ModalProps, Stack, useTheme } from "@mui/material";

// Data
import { IRideBulkUpdateKeys } from "app/data/types";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getRidesBulkUpdateKeyInitialValues,
  ridesBulkUpdateKeyValidationSchema,
} from "app/data/helpers";
import {
  selectAccountKeysState,
  selectDrawerSelectedAccountId,
} from "app/data/selectors";
import {
  getAllKeysByAccountThunk,
  updateRideBulkUploadKeysThunk,
} from "app/features";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

type Props = Omit<ModalProps, "title" | "children"> & {
  rides: string[];
  afterSubmitActionHandler: () => void;
};

const BulkRidesUpdateDriverForm: FC<Props> = ({
  open,
  onClose,
  rides,
  afterSubmitActionHandler,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [customIsSubmitting, setCustomIsSubmitting] = useState<boolean>(false);

  // Global State
  const accountId = useAppSelector(selectDrawerSelectedAccountId);
  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  useEffect(() => {
    dispatch(getAllKeysByAccountThunk(accountId ?? ""));
  }, [accountId]);

  const initialValues = useMemo(
    () => (!isEmpty(rides) ? getRidesBulkUpdateKeyInitialValues(rides) : {}),
    [rides]
  );

  const saveChangesHandler = async (
    values: IRideBulkUpdateKeys,
    formikHelpers: FormikHelpers<IRideBulkUpdateKeys>
  ) => {
    formikHelpers.setSubmitting(true);
    setCustomIsSubmitting(true);

    const { type } = await dispatch(
      updateRideBulkUploadKeysThunk({
        accountId: accountId ?? "",
        data: values,
      })
    );

    if (type === "objects/updateRideBulkUploadKeysThunk/fulfilled") {
      const handleEventRaised = (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.RideKeyBulkUpdatedEvent
        ) {
          formikHelpers.setSubmitting(false);
          setCustomIsSubmitting(false);

          toast.success("Bulk update for the object keys has been completed.");

          afterSubmitActionHandler && afterSubmitActionHandler();

          // @ts-ignore
          onClose();
        }

        SignalRService.hubConnection?.off("EventRaised");
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setCustomIsSubmitting(false);
    }
  };

  return (
    <Formik
      // @ts-ignore
      initialValues={initialValues}
      validationSchema={ridesBulkUpdateKeyValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      {({ resetForm, isValid, isSubmitting, handleSubmit }) => (
        <Modal
          open={open}
          onClose={() => {
            // @ts-ignore
            onClose();
            resetForm();
          }}
          title={t("common.changeDriver")}
          primaryBtnProps={{
            type: "submit",
            text: t("buttonsText.saveChanges"),
            loading: isSubmitting || customIsSubmitting,
            disabled: !isValid,
            // @ts-ignore
            onClick: handleSubmit,
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel"),
            onClick: () => {
              // @ts-ignore
              onClose();
              resetForm();
            },
          }}
          theme={theme}
        >
          <Stack spacing={2}>
            <FormikSelectField
              theme={theme}
              label={t("keyId.label", { ns: "formFieldsTranslation" })}
              placeholder={t("keyId.placeholder", {
                ns: "formFieldsTranslation",
              })}
              name="keyId"
              options={accountKeys ?? []}
              optionLabelKey={"label"}
              optionValueKey={"uniqueId"}
              loading={accountKeysLoading}
              required
            />
          </Stack>
        </Modal>
      )}
    </Formik>
  );
};

export default BulkRidesUpdateDriverForm;
