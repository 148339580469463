import * as Yup from "yup";
import { IResetPassword, INewPassword } from "app/data/types";

export const resetPasswordInitialValues: IResetPassword = { email: "" };

export const resetPasswordValidationSchema: Yup.Schema<IResetPassword> =
  Yup.object().shape({
    email: Yup.string().email().required().label("Email"),
  });

export const newPasswordInitialValues: INewPassword = {
  newPassword: "",
  confirmPassword: "",
};

export const newPasswordValidationSchema: Yup.Schema<INewPassword> =
  Yup.object().shape({
    newPassword: Yup.string()
      .trim()
      .required()
      .min(8, "Password must be at least 8 characters")
      .max(256, "Password must be at most 256 characters")
      .test(
        "password-strength",
        "Password must include at least 3 of the following: lowercase letters, uppercase letters, numbers, symbols",
        (value) => {
          const conditionsMet = [
            /[a-z]/.test(value || ""), // Lowercase letters
            /[A-Z]/.test(value || ""), // Uppercase letters
            /\d/.test(value || ""), // Numbers
            /[\W_]/.test(value || ""), // Symbols
          ].filter(Boolean).length; // Count the number of true conditions
          return conditionsMet >= 3;
        }
      )
      .label("New Password"),
    confirmPassword: Yup.string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      })
      .label("Confirm Password")
      .required(),
  });
