import { FC } from "react";
import { Stack, Box } from "@mui/material";
import { ContentSectionLayout } from "@vilocnv/allsetra-core";
import { InfoDetailContainer } from "../../activationPortalSections.styled";
import { IActivationPortalInitialValues } from "app/data/types";
import { specificSubscriptionPlanInformation } from "app/data/helpers";
import { SUBSCRIPTION_TYPES } from "app/data/constants/activationPortalConstants";

interface Props extends IActivationPortalInitialValues {
  subscriptions: Array<any>;
  order: number;
  translator: any;
}
const SubscriptionOverview: FC<Props> = ({
  initialValues,
  subscriptions,
  order = 2,
  translator,
}) => {
  const subscriptionOverviewData = specificSubscriptionPlanInformation({
    subscriptions,
    subscriptionId: initialValues.subscriptionId,
  });

  const { pricePerYear, subscriptionPlan } = subscriptionOverviewData;

  return (
    <Stack spacing={3}>
      <h5>
        {order}.{" "}
        {translator("titles.subscription", {
          ns: "activationPortal",
        })}
      </h5>
      <Box>
        <ContentSectionLayout
          title={subscriptionPlan.name || "object name 1"}
          subTitle=""
        >
          <InfoDetailContainer>
            <h6>
              {translator("headings.type", {
                ns: "activationPortal",
              })}
            </h6>
            <p>{SUBSCRIPTION_TYPES.PER_MONTH}</p>
          </InfoDetailContainer>

          <InfoDetailContainer hideDivider>
            <h6>
              {translator("headings.price", {
                ns: "activationPortal",
              })}
            </h6>
            <p>{pricePerYear ? `${pricePerYear}/month` : ""}</p>
          </InfoDetailContainer>
        </ContentSectionLayout>
      </Box>
    </Stack>
  );
};

export default SubscriptionOverview;
