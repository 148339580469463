import { FC } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import InnerForm from "./children/InnerForm";
import {
  EmailVerificationInitialValues,
  EmailVerificationValidationSchema,
} from "app/data/helpers/fietsgestolenHelpers";
import { AllsetraTriangles, PlusTwo } from "assets/icons";
import {
  LogoWrapper,
  TrianglesWrapper,
  VectorSVGs,
} from "../FietsgestolenForms.styled";
import { IAddUserEmail } from "app/data/types";
import { useAppDispatch, useAppSelector } from "hooks";
import { setUserEmail, verifyEmailThunk } from "app/features";
import { selectUserDataState } from "app/data/selectors";

interface Props {
  gotoNextStep: () => void;
}

const ActivateVerificationForm: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  const { emailLoading } = useAppSelector(selectUserDataState);

  const onSubmitHandler = async (
    value: IAddUserEmail,
    formikHelpers: FormikHelpers<IAddUserEmail>
  ) => {
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(verifyEmailThunk(value));

    if (type === "fietsgestolen/verifyEmailThunk/fulfilled") {
      formikHelpers.resetForm();
      dispatch(setUserEmail(value.email));
      gotoNextStep();
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={EmailVerificationInitialValues}
      onSubmit={onSubmitHandler}
      validationSchema={EmailVerificationValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <Form>
          <VectorSVGs>
            <PlusTwo style={{ position: "absolute", right: 0, top: "25vh" }} />
          </VectorSVGs>

          <LogoWrapper>
            <TrianglesWrapper>
              <AllsetraTriangles />
            </TrianglesWrapper>
          </LogoWrapper>

          <StepScreenLayout
            title="Activeren diefstalbeveiliging"
            subtitle="Voer je e-mailadres in en ontvang een verificatiemail. Check je spambox als je geen e-mail hebt ontvangen."
            disabled={!isValid}
            onButtonSubmit={handleSubmit}
            buttonText="Verstuur verificatiemail"
            isFietsgestolen={true}
            loading={emailLoading}
          >
            <InnerForm />
          </StepScreenLayout>
        </Form>
      )}
    </Formik>
  );
};

export default ActivateVerificationForm;
