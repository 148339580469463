import * as Yup from "yup";

export const co2ReportsFilterFormInitialValues = {
  objectType: [],
  group: [],
  object: [],
  key: [],
  // rideMode: [], // TODO will be added later
  // excludeRides: false, // TODO will be added later
};
