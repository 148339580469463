import { FC } from "react";
import { Theme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { Modal, ModalProps } from "@vilocnv/allsetra-core";
import {
  exportReportsFormInitialValues,
  exportReportsFormValidationSchema,
} from "app/data/helpers";
import InnerForm from "./children/InnerForm";
import { useTranslation } from "react-i18next";

export type ExportReportsFormProps = Pick<ModalProps, "open" | "onClose"> & {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  submitting?: boolean;
  theme?: Theme;
};

const ExportReportsForm: FC<ExportReportsFormProps> = ({
  open,
  onClose,
  onSubmit,
  theme,
  submitting = false,
}) => {
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  return (
    <Formik
      initialValues={exportReportsFormInitialValues}
      validationSchema={exportReportsFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        resetForm,
        setFieldValue,
        values,
      }) => (
        <Modal
          open={open}
          onClose={() => {
            onClose();
            resetForm();
          }}
          title={t("exportReport.title")}
          primaryBtnProps={{
            type: "submit",
            text: t("buttonsText.save"),
            loading: isSubmitting || submitting,
            // @ts-ignore
            onClick: handleSubmit,
            disabled: !isValid,
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel"),
            onClick: () => {
              onClose();
              resetForm();
            },
          }}
          theme={theme}
          size="medium"
        >
          <InnerForm translator={t} setFieldValue={setFieldValue} />
        </Modal>
      )}
    </Formik>
  );
};

export default ExportReportsForm;
