import { FC, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { Topbar, Table, useDispatchOnParams } from "@vilocnv/allsetra-core";
import { useNavigate } from "react-router-dom";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { ALL_OBJECTS_TABLE_COLUMNS } from "app/data/constants";
import { getObjectsByQueryThunk, setObjectActiveTabIndex } from "app/features";
import {
  selectDrawerSelectedAccountId,
  selectQueriedObjectsState,
} from "app/data/selectors";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "app/integrations/microsoftInsights";
import { useTranslation } from "react-i18next";

const Objects: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Global States
  const { objects, totalRecords, loading } = useAppSelector(
    selectQueriedObjectsState
  );
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(() => ALL_OBJECTS_TABLE_COLUMNS(t), [t]);

  useDispatchOnParams(getObjectsByQueryThunk, {
    args: {
      accountId: drawerSelectedAccountId,
    },
    orderBy: [{ field: "name", ascending: true }],
  });

  const rowClickHandler = (row: any) => {
    dispatch(setObjectActiveTabIndex(0));
    navigate({
      pathname: `/dashboard/objects/details/${row.uniqueId}`,
    });
  };

  return (
    <Box>
      <Topbar theme={theme} title={t("drawerMenuLinks.objects")} />
      <Box mx={4}>
        <Table
          columns={tableColumns}
          data={objects}
          progressPending={loading}
          paginationTotalRows={totalRecords}
          searchPlaceholder={t("common.search")}
          onRowClicked={rowClickHandler}
          sortServer
        />
      </Box>
    </Box>
  );
};

export default withAITracking(reactPlugin, Objects);
