import { TableColumn } from "@vilocnv/allsetra-core";
import moment from "moment";
import {
  COMMON_REPORT_INNER_TABLE_COLUMNS,
  COMMON_REPORT_TABLE_COLUMNS,
} from "./commonReportsConstants";
import { calculateCO2Emissions } from "app/data/helpers";

export const CO2_REPORT_DATE_RENDERING: TableColumn<any>[] = [
  {
    selector: (row: any) => <div style={{ width: "220px" }}>{row.label}</div>,
  },
  {},
  {},
  {},
  {},
  {},
  {},
  {
    selector: (row: any) => (
      <div style={{ paddingLeft: "60px" }}>
        {calculateCO2Emissions(row.data)}
      </div>
    ),
  },
];

export const CO2_REPORT_TABLE_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: translator("tableHeading.rideNumber", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: any) => <div>{row?.name || "N/A"}</div>,
    wrap: true,
  },
  {
    name: translator("tableHeading.startTime", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.endTime", {
      ns: "tableHeadingsTranslation",
    }),
  },
  ...COMMON_REPORT_TABLE_COLUMNS(translator),
  {
    name: "CO2",
    selector: (row: any) => (
      <div style={{ paddingLeft: "60px" }}>{row?.CO2Emissions || "0"}</div>
    ),
  },
];

export const CO2_REPORT_INNER_TABLE_COLUMNS = () => {
  return [
    { selector: (row: any) => row?.rideId || "-" },
    {
      selector: (row: any) =>
        moment(row?.startDate).format("DD/MM/YY HH:mm:ss") || "-",
    },
    {
      selector: (row: any) =>
        row?.endDate !== null
          ? moment(row?.endDate).format("DD/MM/YY HH:mm:ss")
          : "-",
    },
    ...COMMON_REPORT_INNER_TABLE_COLUMNS(),
    {
      cell: (row: any) => {
        return Math.round(row?.cO2Emissions) || "-";
      },
    },
  ];
};

export const CO2_REPORT_HEADERS = [
  { label: "Object Name", key: "Object Name" },
  { label: "Ride", key: "Ride" },
  { label: "Start Mileage", key: "Start Mileage" },
  { label: "End Mileage", key: "End Mileage" },
  { label: "Start Time", key: "Start Time" },
  { label: "End Time", key: "End Time" },
  { label: "Start Address", key: "Start Address" },
  { label: "End Address", key: "End Address" },
  { label: "Key", key: "Key" },
  { label: "Ride Mode", key: "Ride Mode" },
  { label: "Distance", key: "Distance" },
  { label: "Ride Duration", key: "Ride Duration" },
  { label: "CO2", key: "CO2" },
];
