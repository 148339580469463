import { Badge, TableColumn } from "@vilocnv/allsetra-core";
import { getNextDeliveryTime } from "app/data/helpers";
import { convertToLocalTime } from "app/data/helpers";

export const SCHEDULE_REPORTS_HEADERS = [
  { label: "Report", key: "Object Name" },
  { label: "Type", key: "Session ID" },
  { label: "Duration", key: "Time Start" },
  { label: "Status", key: "Time End" },
  { label: "More Options", key: "Duration" },
];

export const REPORT_TYPES = [
  { name: "Mileage Report", uniqueId: 2 },
  { name: "Working Hours", uniqueId: 3 },
  { name: "CO2 Reports", uniqueId: 4 },
  { name: "Multiple Rides Reports", uniqueId: 5 },
];

export const DELIVERY_TIME = [
  { name: "9:00", uniqueId: "09:00:00" },
  { name: "12:00", uniqueId: "12:00:00" },
  { name: "15:00", uniqueId: "15:00:00" },
  { name: "18:00", uniqueId: "18:00:00" },
  { name: "21:00", uniqueId: "21:00:00" },
  { name: "00:00", uniqueId: "00:00:00" },
];

export const DELIVERY_FREQUENCY_OPTIONS = (t: any) => {
  return [
    {
      id: 0,
      name: t("deliverFrequencyOptions.daily"),
    },
    {
      id: 1,
      name: t("deliverFrequencyOptions.weekly"),
    },
    {
      id: 2,
      name: t("deliverFrequencyOptions.monthly"),
    },
  ];
};

export const EXPORT_TYPE_OPTIONS = (t: any) => {
  return [
    {
      id: 0,
      name: t("exportTypeOptions.csv"),
    },
    {
      id: 1,
      name: t("exportTypeOptions.excel"),
    },
    {
      id: 2,
      name: t("exportTypeOptions.pdfTable"),
    },
    {
      id: 3,
      name: t("exportTypeOptions.pdfGraphical"),
    },
  ];
};

const REPORT_TYPE = {
  2: "Mileage Report",
  3: "Working Hours Report",
  4: "CO2 Reports",
  5: "Multiple Rides Reports",
};

const DELIVERY_FREQUENCY = (t: any, value: number) => {
  const frequencyValues = {
    0: t("deliverFrequencyOptions.daily"),
    1: t("deliverFrequencyOptions.weekly"),
    2: t("deliverFrequencyOptions.monthly"),
  };
  //@ts-ignore
  return frequencyValues[value as keyof frequencyValues];
};

export const SCHEDULE_REPORTS_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: "",
    selector: (row: any) => row?.body || "N/A",
  },
  {
    name: "",
    selector: (row: any) =>
      //@ts-ignore
      REPORT_TYPE[row?.reportDefinition.reportType] || "N/A",
    wrap: true,
  },
  {
    name: "",
    selector: (row: any) => (
      <>
        <strong>
          {/* @ts-ignore */}
          {DELIVERY_FREQUENCY(translator, row?.deliveryFrequencyType) || "N/A"}
        </strong>
        <div>
          {getNextDeliveryTime(
            row?.reportDefinition?.daysOfWeek
              ? row?.reportDefinition?.daysOfWeek
              : [],
            convertToLocalTime(row.deliveryTime),
            row.deliveryFrequencyType
          )}
        </div>
      </>
    ),
  },
  {
    name: "",
    selector: (row: any) => (
      <Badge colorScheme={row.status == 0 ? "error" : "success"}>
        {row.status === 0
          ? translator("tableHeading.inactive", {
              ns: "tableHeadingsTranslation",
            })
          : translator("tableHeading.active", {
              ns: "tableHeadingsTranslation",
            })}
      </Badge>
    ),
  },
];
