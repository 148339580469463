import { FC, useState } from "react";
import { DrawerLayout, DrawerLayoutProps } from "@vilocnv/allsetra-core";
import {
  ActiveIconBox,
  StepsMenu,
  StepsMenuItem,
  ActiveStepBody,
} from "./StepperDrawerLayout.styled";
import ActiveStepIcon from "components/ui/icons/ActiveStepIcon";

export interface IStepItem {
  index: number;
  name: string;
  isActive: boolean;
  isCompleted: boolean;
}

export interface StepperDrawerLayoutProps
  extends Omit<
    DrawerLayoutProps,
    "isDrawerCollapsed" | "toggleDrawerCollapseState"
  > {
  steps: Array<IStepItem>;
  onCompletedStepClick: (step: IStepItem) => void;
}

const StepperDrawerLayout: FC<StepperDrawerLayoutProps> = ({
  size,
  appLogoType,
  appLogoSize,
  steps,
  onCompletedStepClick,
  whiteLabel,
  children,
}) => {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false);

  const toggleMobileDrawer = () => setIsMobileDrawerOpen(!isMobileDrawerOpen);

  const stepItemClickHandler = (step: IStepItem) => {
    if (step.isCompleted) {
      onCompletedStepClick(step);
    }
  };

  return (
    <main>
      <DrawerLayout
        size={size}
        isDrawerCollapsed={false}
        toggleDrawerCollapseState={() => {}}
        isMobileDrawerOpen={isMobileDrawerOpen}
        toggleMobileDrawer={toggleMobileDrawer}
        appLogoType={appLogoType}
        appLogoSize={appLogoSize}
        whiteLabel={whiteLabel}
      >
        <StepsMenu>
          {steps &&
            steps.map((step: any, i: number) => (
              <StepsMenuItem
                key={`${i}`}
                isActive={step?.isActive}
                isCompleted={step?.isCompleted}
                onClick={() => stepItemClickHandler(step)}
              >
                <ActiveIconBox>
                  {step.isActive && <ActiveStepIcon />}
                </ActiveIconBox>
                {step.name}
              </StepsMenuItem>
            ))}
        </StepsMenu>
      </DrawerLayout>
      <ActiveStepBody size={size} isDrawerCollapsed={false}>
        {children}
      </ActiveStepBody>
    </main>
  );
};

export default StepperDrawerLayout;
