import { FC, memo, useMemo } from "react";
import { Table, TableColumn } from "@vilocnv/allsetra-core";
import { CO2_REPORT_INNER_TABLE_COLUMNS } from "app/data/constants";

const customStyles = {
  cells: {
    style: {
      justifyContent: "center",
    },
  },
};

interface CO2ReportFinalDataProps {
  data: any;
}

const CO2ReportFinalData: FC<CO2ReportFinalDataProps> = ({ data }) => {
  const tableColumns: TableColumn<any>[] = useMemo(
    () => CO2_REPORT_INNER_TABLE_COLUMNS(),
    []
  );

  return (
    <Table
      columns={tableColumns}
      data={data.data}
      noTableHead={true}
      pagination={false}
      customStyles={customStyles}
    />
  );
};

export default memo(CO2ReportFinalData);
