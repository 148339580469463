import { FC } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  FormikSelectField,
  FormikSelectSearchField,
} from "@vilocnv/allsetra-core";
import DateAndTimeFilters from "../../DateAndTimeFilters/DateAndTimeFilters";

interface Props {
  groups: Array<any>;
  objectTypes: Array<any>;
  objects: Array<any>;
  dataLoading?: boolean;
  translator: any;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  isReportsHistoryPage?: boolean;
}

const InnerForm: FC<Props> = ({
  groups,
  objectTypes,
  objects,
  dataLoading = false,
  translator,
  fetchOnDebounce,
  onChange,
  isReportsHistoryPage = false,
}) => {
  const theme = useTheme();
  return (
    <Stack spacing={2}>
      <FormikSelectField
        theme={theme}
        label={translator("objectType.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("objectType.placeholder", {
          ns: "formFieldsTranslation",
        })}
        name="objectType"
        options={objectTypes ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
      />
      <FormikSelectField
        theme={theme}
        label={translator("groups.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("groups.placeholder", {
          ns: "formFieldsTranslation",
        })}
        name="group"
        options={groups ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
      />
      <FormikSelectSearchField
        fetchOnDebounce={fetchOnDebounce}
        label={translator("objects.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("objects.placeholder", {
          ns: "formFieldsTranslation",
        })}
        name="object"
        options={objects ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
        onChange={onChange}
      />
      {!isReportsHistoryPage && <DateAndTimeFilters />}
    </Stack>
  );
};

export default InnerForm;
