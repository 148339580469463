import { FC, Dispatch, SetStateAction } from "react";
import { useTheme } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";
import { GroupBy } from "app/data/helpers";
import { useTranslation } from "react-i18next";

interface FilterButtonProps {
  dateFormat: string;
  setDateFormat: Dispatch<SetStateAction<GroupBy>>;
}

const ReportDateFormats: FC<FilterButtonProps> = ({
  dateFormat,
  setDateFormat,
}) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <div>
      <Button
        id="reports-filter-day"
        text={t("dateFormats.day")}
        size="small"
        variant={`${dateFormat}` === "day" ? "contained" : "outlined"}
        theme={theme}
        onClick={() => setDateFormat("day")}
        sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      />
      <Button
        id="reports-filter-week"
        text={t("dateFormats.week")}
        size="small"
        variant={`${dateFormat}` === "week" ? "contained" : "outlined"}
        theme={theme}
        onClick={() => setDateFormat("week")}
        sx={{ borderRadius: 0 }}
      />
      <Button
        id="reports-filter-month"
        text={t("dateFormats.month")}
        size="small"
        variant={`${dateFormat}` === "month" ? "contained" : "outlined"}
        theme={theme}
        onClick={() => setDateFormat("month")}
        sx={{ borderRadius: 0 }}
      />
      <Button
        id="reports-filter-year"
        text={t("dateFormats.year")}
        size="small"
        variant={`${dateFormat}` === "year" ? "contained" : "outlined"}
        theme={theme}
        onClick={() => setDateFormat("year")}
        sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, mr: 2 }}
      />
    </div>
  );
};

export default ReportDateFormats;
