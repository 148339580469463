import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import {
  ContentSectionLayout,
  FormikInputField,
  FormikSelectField,
  TwoColsLayout,
  FormikTimeFrameField,
  AddressFormSection,
} from "@vilocnv/allsetra-core";

// Data
import { useAppSelector, useDispatchOnMount } from "hooks";
import {
  selectAccountMetadataState,
  selectAccountsState,
} from "app/data/selectors";
import {
  getAccountsIndustriesThunk,
  getAccountsTypesThunk,
} from "app/features";
import { useTranslation } from "react-i18next";

interface Props {
  countries: Array<any>;
  countriesLoading: boolean;
}

const GeneralInformationForm: FC<Props> = ({ countries, countriesLoading }) => {
  const {
    accountsIndustriesLoading,
    accountsIndustries,
    accountsTypesLoading,
    accountsTypes,
  } = useAppSelector(selectAccountMetadataState);
  const theme = useTheme();

  const { allAccounts } = useAppSelector(selectAccountsState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  useDispatchOnMount(
    getAccountsIndustriesThunk,
    accountsIndustries.length ? undefined : true
  );

  useDispatchOnMount(
    getAccountsTypesThunk,
    accountsTypes.length ? undefined : true
  );

  return (
    <ContentSectionLayout
      title={t("titles.generalInfo")}
      subTitle={t("descriptions.generalInfo")}
    >
      <Box
        sx={{
          marginTop: {
            xs: "32px",
            md: "0px",
          },
        }}
      >
        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label={t("accountNumber.label", { ns: "formFieldsTranslation" })}
            name="accountNumber"
            placeholder={t("accountNumber.placeholder", {
              ns: "formFieldsTranslation",
            })}
            disabled
            fullWidth
          />
          <FormikInputField
            theme={theme}
            label={t("accountName.label", { ns: "formFieldsTranslation" })}
            name="name"
            placeholder={t("accountName.placeholder", {
              ns: "formFieldsTranslation",
            })}
            required
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikSelectField
            theme={theme}
            label={t("accountType.label", { ns: "formFieldsTranslation" })}
            name="accountType"
            options={accountsTypes || []}
            optionLabelKey={"name"}
            optionValueKey={"id"}
            loading={accountsTypesLoading}
            fullWidth
          />
          <FormikSelectField
            theme={theme}
            label={t("accountIndustry.label", { ns: "formFieldsTranslation" })}
            name="accountIndustry"
            optionLabelKey={"name"}
            optionValueKey={"id"}
            options={accountsIndustries || []}
            loading={accountsIndustriesLoading}
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikSelectField
            theme={theme}
            label={t("parentAccount.label", { ns: "formFieldsTranslation" })}
            placeholder={t("parentAccount.placeholder", {
              ns: "formFieldsTranslation",
            })}
            options={allAccounts ?? []}
            optionLabelKey={"accountName"}
            optionValueKey={"accountId"}
            name="parentId"
            searchable
            disabled
            fullWidth
          />
          <FormikInputField
            theme={theme}
            label={t("kvkNumber.label", { ns: "formFieldsTranslation" })}
            placeholder={t("kvkNumber.placeholder", {
              ns: "formFieldsTranslation",
            })}
            name="kvkcocNumber"
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label="BOBO ID"
            placeholder="BOBO ID"
            name="boboid"
            fullWidth
            disabled
          />
          <FormikInputField
            theme={theme}
            label="Multi-viewer ID"
            placeholder="Multi-viewer ID"
            name="multiViewerId"
            fullWidth
            disabled
          />
        </TwoColsLayout>
        <AddressFormSection
          title={t("titles.visitingAddress")}
          parentKey="visitingAddress"
          countries={countries ?? []}
          countriesLoading={countriesLoading}
          translator={t}
        />
        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label="Website"
            placeholder="Website URL"
            name="website"
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout fullWidth>
          <FormikInputField
            theme={theme}
            label={t("accountNote.label", { ns: "formFieldsTranslation" })}
            placeholder={t("accountNote.placeholder", {
              ns: "formFieldsTranslation",
            })}
            name="notes"
            fullWidth
            multiline
            rows={2}
          />
        </TwoColsLayout>
        <TwoColsLayout fullWidth hideDivider>
          <FormikTimeFrameField
            theme={theme}
            label={t("defaultWorkingHours.label", {
              ns: "formFieldsTranslation",
            })}
            typeName="workingHoursType"
            name="workingHours.workingHoursSchedule"
            translator={t}
          />
        </TwoColsLayout>
      </Box>
    </ContentSectionLayout>
  );
};

export default GeneralInformationForm;
