import { FC } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import InnerForm from "./children/InnerForm";
import {
  CodeVerificationInitialValues,
  CodeVerificationValidationSchema,
} from "app/data/helpers/fietsgestolenHelpers";
import { AllsetraTriangles, PlusTwo } from "assets/icons";
import {
  LogoWrapper,
  TrianglesWrapper,
  VectorSVGs,
} from "../FietsgestolenForms.styled";
import { useAppDispatch, useAppSelector } from "hooks";
import { IAddVerificationCode } from "app/data/types";
import {
  listObjectsThunk,
  setVerificationCodeFietsgestolen,
  verifyCodeThunk,
} from "app/features";
import { selectUserDataState } from "app/data/selectors";

interface Props {
  gotoNextStep: () => void;
}

const CodeVerificationForm: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  const { userEmail, verificationCodeLoadingFietsgestolen } =
    useAppSelector(selectUserDataState);

  const onSubmitHandler = async (
    value: IAddVerificationCode,
    formikHelpers: FormikHelpers<IAddVerificationCode>
  ) => {
    formikHelpers.setSubmitting(true);

    const formattedData = {
      email: userEmail,
      ...value,
    };

    const verifyCodeAction = await dispatch(verifyCodeThunk(formattedData));
    dispatch(listObjectsThunk(formattedData));

    if (verifyCodeAction.type === "fietsgestolen/verifyCodeThunk/fulfilled") {
      formikHelpers.resetForm();
      dispatch(setVerificationCodeFietsgestolen(value.verificationCode));
      gotoNextStep();
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={CodeVerificationInitialValues}
      onSubmit={onSubmitHandler}
      validationSchema={CodeVerificationValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <Form>
          <VectorSVGs>
            <PlusTwo style={{ position: "absolute", right: 0, top: "25vh" }} />
          </VectorSVGs>

          <LogoWrapper>
            <TrianglesWrapper>
              <AllsetraTriangles />
            </TrianglesWrapper>
          </LogoWrapper>

          <StepScreenLayout
            title="Diefstal melden"
            subtitle="Voer je e-mailadres in om je diefstal te melden en ontvang een verificatiemail. Check je spambox als je geen e-mail hebt ontvangen."
            disabled={!isValid}
            onButtonSubmit={handleSubmit}
            buttonText="Verstuur verificaticode"
            isFietsgestolen={true}
            loading={verificationCodeLoadingFietsgestolen}
          >
            <InnerForm />
          </StepScreenLayout>
        </Form>
      )}
    </Formik>
  );
};

export default CodeVerificationForm;
