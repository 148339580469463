import { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { Button, FormikInputField, toast } from "@vilocnv/allsetra-core";
import BasicPageLayout from "components/layouts/BasicPageLayout";
import { CtaText } from "pages/activationPortal/activationPortal.styled";

// Data
import { useAppSelector } from "hooks";
import { selectWhiteLabelsState } from "app/data/selectors";
import {
  newPasswordInitialValues,
  newPasswordValidationSchema,
} from "app/data/helpers";
import { INewPassword } from "app/data/types";
import { AuthService } from "app/data/services";
import { useTranslation } from "react-i18next";

const NewPassword: FC = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { whiteLabel, loading } = useAppSelector(selectWhiteLabelsState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  // Local State
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitHandler = (values: INewPassword) => {
    setIsLoading(true);

    AuthService.authResetPassword({
      password: values.newPassword,
      confirmationCode: searchParams.get("code") ?? "",
    })
      .then((res) => {
        if (res.status === 202) {
          toast.success("Your password has been reset successfully");
          navigate("/");
        } else {
          toast.error("Server side error occured.");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BasicPageLayout
      title={t("titles.resetYourPassword")}
      whiteLabelBgImage={whiteLabel?.backgroundImageUrl}
      loading={loading}
    >
      <Formik
        initialValues={newPasswordInitialValues}
        validationSchema={newPasswordValidationSchema}
        onSubmit={submitHandler}
        validateOnMount
        enableReinitialize
      >
        {({ isValid, handleSubmit }) => (
          <Stack gap={2}>
            <FormikInputField
            theme={theme}
              type="password"
              label={t("newPassword.label", {
                ns: "formFieldsTranslation",
              })}
              placeholder={t("newPassword.label", {
                ns: "formFieldsTranslation",
              })}
              name="newPassword"
              colorTheme={isDownMd ? "light" : "dark"}
            />
            <FormikInputField
            theme={theme}
              type="password"
              label={t("confirmPassword.label", {
                ns: "formFieldsTranslation",
              })}
              placeholder={t("confirmPassword.label", {
                ns: "formFieldsTranslation",
              })}
              name="confirmPassword"
              colorTheme={isDownMd ? "light" : "dark"}
            />
            <Button
              theme={theme}
              disabled={!isValid}
              // @ts-ignore
              onClick={handleSubmit}
              loading={isLoading}
            >
              Reset
            </Button>
            <CtaText>
              {t("typography.backTo")}{" "}
              <span onClick={() => navigate("/")}>Login page</span>
            </CtaText>
          </Stack>
        )}
      </Formik>
    </BasicPageLayout>
  );
};

export default NewPassword;
