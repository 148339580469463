import { FC, useEffect, useMemo, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import {
  ChangeGroupForm,
  DeleteConfirmationModal,
  GroupCard,
  toast,
  Topbar,
  types,
} from "@vilocnv/allsetra-core";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector, useSelectSearch } from "hooks";
import { FormikHelpers } from "formik";
import {
  selectAccountGroups,
  selectDrawerSelectedAccountId,
  selectQueriedObjectsState,
} from "app/data/selectors";
import { GroupsMessageBox } from "./Groups.styled";
import {
  createOrUpdateAccountGroupsThunk,
  getAllAccountGroupsThunk,
  getAllAccountObjectsThunk,
  getObjectsByQueryThunk,
  removeGroupFromAccountThunk,
} from "app/features";
import { signalRGenerateSuccessToastMessage } from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

const Groups: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { accountGroups } = useAppSelector(selectAccountGroups);

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { objects } = useAppSelector(selectQueriedObjectsState);

  // Local State
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [editValues, setEditValues] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedLocalIds, setSelectedLocalIds]: Array<any> = useState([]);

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation",
  ]);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId));
      dispatch(getAllAccountObjectsThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  const openEditModal = (item: any) => {
    setEditValues(item);
    setAddGroupModal(true);
    setSelectedGroupId(item.uniqueId);
  };

  const openDeleteConfirmationModal = (groupId: string) => {
    setSelectedGroupId(groupId);
    setOpenDeleteModal(true);
  };

  const toggleAddGroupModal = () => {
    setAddGroupModal(!addGroupModal);
    setEditValues({});
  };

  const removeGroupHandler = async () => {
    if (!selectedGroupId || !drawerSelectedAccountId) return;

    setIsDeleting(true);

    const { type } = await dispatch(
      removeGroupFromAccountThunk({
        accountId: drawerSelectedAccountId,
        groupId: selectedGroupId,
      })
    );

    if (type === "accounts/removeGroupFromAccountThunk/fulfilled") {
      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.GroupRemovedFromAccountEvent
        ) {
          setOpenDeleteModal(false);
          setIsDeleting(false);
          toast.success("Group has been deleted successfully.");
          dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId ?? ""));
        }

        SignalRService.hubConnection?.off("EventRaised");
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      setIsDeleting(false);
    }
  };

  const addGroupHandler = async (
    values: types.IChangeGroup,
    formikHelpers: FormikHelpers<types.IChangeGroup>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmitting(true);

    const { type } = await dispatch(
      createOrUpdateAccountGroupsThunk({
        accountId: drawerSelectedAccountId,
        values: { ...values },
        groupId: values.uniqueId,
      })
    );

    if (type === "accounts/createOrUpdateAccountGroupsThunk/fulfilled") {
      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.AccountGroupUpdatedEvent ||
          event.eventName ===
            types.BackendEventsEnum.GroupAssignedToAccountEvent
        ) {
          toggleAddGroupModal();
          formikHelpers.resetForm();
          formikHelpers.setSubmitting(false);
          setIsSubmitting(false);
          toast.success(
            signalRGenerateSuccessToastMessage(
              event.eventName,
              "Group",
              values.uniqueId ? "updated" : "created"
            )
          );
          dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId ?? ""));
        }

        SignalRService.hubConnection?.off("EventRaised");
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  const handleObjectsSearch = (value: string) => {
    dispatch(
      getObjectsByQueryThunk({
        accountId: drawerSelectedAccountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }],
        },
      })
    );
  };

  const extractObjects = useMemo(() => {
    return selectedGroupId
      ? accountGroups.find(
          (item: { uniqueId: string }) => item.uniqueId === selectedGroupId
        )?.objects || []
      : [];
  }, [selectedGroupId, accountGroups]);

  const selectedLocalObjects = useSelectSearch(selectedLocalIds, objects);

  const handleOnChangeGroupForm = (val: any) => {
    setSelectedLocalIds(val);
  };

  let formObjects = [...extractObjects, ...selectedLocalObjects];

  return (
    <Box>
      {drawerSelectedAccountId ? (
        <Box>
          <Topbar
            theme={theme}
            title={t("drawerMenuLinks.groups")}
            primaryButton={{
              id: "group-add-form",
              variant: "outlined",
              text: t("titles.addGroup"),
              startIcon: <AddIcon />,
              onClick: toggleAddGroupModal,
            }}
          />
          <Box marginLeft={4}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              spacing={{ xs: 2, md: 2 }}
              rowSpacing={{ xs: 4, sm: 6, md: 6 }}
            >
              {accountGroups.map((item, index) => (
                <Grid item key={index}>
                  <GroupCard
                    groupTitle={item.name}
                    onDelete={() => openDeleteConfirmationModal(item.uniqueId)}
                    groupObjects={item.objects}
                    onEdit={() => openEditModal(item)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <ChangeGroupForm
            open={addGroupModal}
            onClose={toggleAddGroupModal}
            accountId={drawerSelectedAccountId}
            objects={formObjects}
            initialValues={editValues}
            theme={theme}
            onSubmit={addGroupHandler}
            submitting={isSubmitting}
            isCustomerPortal={true}
            translator={t}
            isGroups={true}
            handleDebounce={handleObjectsSearch}
            //@ts-ignore
            onChange={handleOnChangeGroupForm}
          />
          <DeleteConfirmationModal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            title="You are about to remove a group from account"
            subTitle="Do you really want to remove this group from account? This process cannot be undone."
            primaryBtnProps={{
              id: "group-remove",
              onClick: removeGroupHandler,
              loading: isDeleting,
            }}
            theme={theme}
          />
        </Box>
      ) : (
        <GroupsMessageBox>
          Please select an account to view groups
        </GroupsMessageBox>
      )}
    </Box>
  );
};

export default Groups;
