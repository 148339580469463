import { createSlice } from "@reduxjs/toolkit";
import { generateReportThunk, resolveAddressThunk } from "./reportsActions";

export interface IReportsState {
  loading: boolean;
  resolvedAddressLoading: boolean;
  report: Array<any>;
  resolvedAddresses: Array<any>;
  reportsFilterData: {};
  hasExceededLimit: boolean | null;
}

const initialState: IReportsState = {
  loading: false,
  resolvedAddressLoading: false,
  report: [],
  resolvedAddresses: [],
  reportsFilterData: {},
  hasExceededLimit: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetReports: (state) => {
      state.report = [];
      state.loading = false;
      state.hasExceededLimit = null;
    },
    setReportsFilterData: (state, action) => {
      state.reportsFilterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Reports Action Cases
    builder.addCase(generateReportThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateReportThunk.fulfilled, (state, action: any) => {
      state.report = action.payload.data;
      state.hasExceededLimit = action.payload.hasExceededLimit;
      state.loading = false;
    });
    builder.addCase(generateReportThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resolveAddressThunk.pending, (state) => {
      state.resolvedAddressLoading = true;
    });
    builder.addCase(resolveAddressThunk.fulfilled, (state, action: any) => {
      state.resolvedAddresses = [
        ...state.resolvedAddresses,
        {
          latitude: action.meta.arg.latitude,
          longitude: action.meta.arg.longitude,
          address: action.payload.resolvedAddress,
        },
      ];
      state.resolvedAddressLoading = false;
    });
    builder.addCase(resolveAddressThunk.rejected, (state) => {
      state.resolvedAddressLoading = false;
    });
  },
});

export * from "./reportsActions";
export const { resetReports, setReportsFilterData } = reportsSlice.actions;
export default reportsSlice.reducer;
