import activationAxiosInstance from "app/activationAxiosInstance";

class ActivationPortal {
  static getInstallation = async (activationCode: string) => {
    return await activationAxiosInstance.get(`/${activationCode}`, {
      headers: { isNotProtected: true },
    });
  };

  static getSubscriptions = async (activationCode: string) => {
    return await activationAxiosInstance.get(
      `/${activationCode}/available-subscriptions`,
      {
        headers: { isNotProtected: true },
      }
    );
  };

  static getInformationSecurityQuestions = async () => {
    return await activationAxiosInstance.get("security-questions", {
      headers: { isNotProtected: true },
    });
  };

  static getMyAccounts = async () => {
    return await activationAxiosInstance.get("my-accounts");
  };

  static activateSubscription = async (data: any) => {
    return await activationAxiosInstance.post("/subscribe", data, {
      headers: { isNotProtected: true },
    });
  };
}

export default ActivationPortal;
