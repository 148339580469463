import {
  MilageRegistrationData,
  MilageRegistrationRecord,
} from "app/data/types";
import moment from "moment";
import momentTimezone from "moment-timezone";
import * as Yup from "yup";
import {
  GroupBy,
  convertToNormalTime,
  getDateKey,
} from "../reportCommonHelpers";
import { sortBy } from "lodash";

export const reportTransformTimeForAPI = (time: string, dateValue: Date) => {
  // Get the current time in the local timezone
  const currentTime = momentTimezone();

  // Get the timezone offset in the format "+02:00"
  const timezoneOffset = currentTime.format("Z");

  // Get the current day
  const date = dateValue
    ? moment(dateValue).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  return `${date}T${time}${timezoneOffset}`;
};

export const getResolvedAddress = (
  row: {
    startLatitude?: number;
    startLongitude?: number;
    endLatitude?: number;
    endLongitude?: number;
  },
  resolvedAddress: Array<{
    latitude: number;
    longitude: number;
    address: string;
  }>,
  key: "start" | "end"
) => {
  const latitudeKey = key === "start" ? "startLatitude" : "endLatitude";
  const longitudeKey = key === "start" ? "startLongitude" : "endLongitude";

  const findAddress = resolvedAddress.find(
    (item: { latitude: number; longitude: number }) =>
      item.latitude === row[latitudeKey as keyof typeof row] &&
      item.longitude === row[longitudeKey as keyof typeof row]
  );

  return findAddress && findAddress?.address ? findAddress.address : "N/A";
};

export const exportReportsFormInitialValues = {
  emailAddress: "",
  exportType: 0,
};

export const exportReportsFormValidationSchema: Yup.Schema = Yup.object({
  emailAddress: Yup.string().email().required().label("Email"),
  exportType: Yup.number().required("Value is Required"),
});

export const co2AndMileageReportsFilterFormValidationSchema: Yup.Schema =
  Yup.object({
    objectType: Yup.array().of(Yup.string()).label("Object Types"),
    group: Yup.array().of(Yup.string()).label("Groups"),
    object: Yup.array().of(Yup.string()).label("Objects"),
    key: Yup.array().of(Yup.string()).label("Drivers"),
  });

type FormattedRecord = Omit<MilageRegistrationRecord, "key"> & {
  label: string;
};

type FormattedData = {
  name: string;
  records: {
    label: string;
    data: FormattedRecord[];
  }[];
}[];

export const co2AndMileageReportsDataFormatter = (
  originalData: MilageRegistrationData,
  groupBy: GroupBy
): FormattedData[] => {
  const formattedDataMap: Map<string, FormattedData> = new Map();

  originalData &&
    originalData?.forEach((item) => {
      let formattedData = formattedDataMap.get(item.name);

      const co2EmissionTotal = calculateCO2Emissions(item?.items);

      if (!formattedData) {
        formattedData = {
          //@ts-ignore
          name: item.name,
          records: [],
          CO2Emissions: co2EmissionTotal,
        };
        //@ts-ignore
        formattedDataMap.set(item.name, formattedData);
      }

      item?.items?.forEach((record) => {
        const dateKey = getDateKey(record.startDate, groupBy);
        //@ts-ignore
        let dateObject = formattedData.records.find(
          (record: any) => record.label === dateKey
        );

        if (!dateObject) {
          dateObject = {
            label: dateKey,
            data: [],
          };
          //@ts-ignore
          formattedData.records.push(dateObject);
        }

        dateObject.data.push({
          ...record,
        });
      });
    });

  return sortBy(Array.from(formattedDataMap.values()), ["name"]);
};

export const convertDatesForFilterPayload = (dateValues: {
  startDate: Date;
  endDate: Date;
}) => {
  const startDate = new Date(dateValues.startDate);
  const endDate = new Date(dateValues.endDate);
  const sameDate = startDate.toDateString() === endDate.toDateString();
  if (sameDate) {
    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);
  }
  const formattedStartDate = convertToNormalTime(startDate);
  const formattedEndDate = convertToNormalTime(endDate, true);

  return { formattedStartDate, formattedEndDate };
};

export const calculateCO2Emissions = (data: Array<any>) => {
  if (!data?.length) return 0;

  return data.reduce(
    //@ts-ignore
    (acc: number, currentItem: { cO2Emissions: number }): number => {
      acc = acc + Math.round(currentItem.cO2Emissions);
      return acc;
    },
    0
  );
};
