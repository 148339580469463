import { FC } from "react";
import { FormikInputField, TwoColsLayout } from "@vilocnv/allsetra-core";
import { Stack, FormLabel, useTheme } from "@mui/material";
import { Label, Checkbox } from "./DateAndTimeFilters.styled";

const DateAndTimeFilters: FC = () => {
  const theme = useTheme();

  return (
    <Stack spacing={2}>
      <div>
        <FormLabel>Select days</FormLabel>
        <TwoColsLayout
          hideDivider
          sxGridContainer={{ marginTop: "4px !important" }}
          sxGridItem={{ paddingTop: "0px !important" }}
        >
          <Label>
            <Checkbox type="checkbox" name="daysOfWeek" value={"1"} />
            Monday
          </Label>
          <Label>
            <Checkbox type="checkbox" name="daysOfWeek" value={"2"} />
            Tuesday
          </Label>
          <Label>
            <Checkbox type="checkbox" name="daysOfWeek" value={"3"} />
            Wednesday
          </Label>
          <Label>
            <Checkbox type="checkbox" name="daysOfWeek" value={"4"} />
            Thursday
          </Label>
          <Label>
            <Checkbox type="checkbox" name="daysOfWeek" value={"5"} />
            Friday
          </Label>
          <Label>
            <Checkbox type="checkbox" name="daysOfWeek" value={"6"} />
            Saturday
          </Label>
          <Label>
            <Checkbox type="checkbox" name="daysOfWeek" value={"0"} />
            Sunday
          </Label>
        </TwoColsLayout>
      </div>
      <FormikInputField
        theme={theme}
        type={"time"}
        name="startTime"
        label={"Start time"}
      />
      <FormikInputField
        theme={theme}
        type={"time"}
        name="endTime"
        label={"End time"}
      />
    </Stack>
  );
};

export default DateAndTimeFilters;
