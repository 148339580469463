import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { IAddGeozone } from "app/data/types";
import { types } from "@vilocnv/allsetra-core";
import { DEFAULT_COORDINATES } from "../constants";

export const geozoneFormInitialValues: IAddGeozone = {
  name: "",
  iconId: "",
  assignedToAllObjects: true,
  assignedObjects: [],
};

//@ts-ignore
export const geozoneFormValidactionSchema: Yup.Schema<IAddGeozone> = Yup.object(
  {
    name: Yup.string().required().label("Geozone name"),
    iconId: Yup.string().required().label("Geozone icon"),
    assignedToAllObjects: Yup.boolean().label("All geozone objects"),
    assignedObjects: Yup.array().label("Assigned objects"),
  }
);

export const transformGeozoneDataForForm = (
  geozone: types.IGeozone,
  geozoneAssignedObjects: Array<string>
): IAddGeozone => {
  //@ts-ignore
  const assignedObjectsIds = geozoneAssignedObjects.map((obj) => obj.uniqueId);
  return {
    name: geozone.name,
    iconId: geozone.icon
      ? geozone.icon.uniqueId
      : JSON.stringify(geozone.iconId),
    assignedToAllObjects: Boolean(geozone.assignedToAllObjects),
    assignedObjects: geozone.assignedToAllObjects ? [] : assignedObjectsIds,
  };
};

export function getPolygonBoundingPaths(polygon: google.maps.Polygon) {
  var polygonBounds = polygon.getPath();
  var bounds = [];

  //@ts-ignore
  for (var i = 0; i < polygonBounds.length; i++) {
    var point = {
      lat: polygonBounds.getAt(i).lat(),
      lng: polygonBounds.getAt(i).lng(),
    };
    bounds.push(point);
  }

  return bounds;
}

export const getGeoJSONFromBoundingPaths = (
  paths: Array<{ lat: number; lng: number }>
) => {
  let geoJSON = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [],
    },
    properties: {},
  };

  for (let point of paths) {
    // @ts-ignore
    geoJSON.geometry.coordinates.push([point.lat, point.lng]);
  }

  return geoJSON;
};

export const createGeozoneObjectForNewlyDrawedPolygon = (
  polygon: google.maps.Polygon
) => {
  let geoJson = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [],
    },
    properties: {},
  };
  for (let point of polygon.getPath().getArray()) {
    // @ts-ignore
    geoJson.geometry.coordinates.push([point.lat(), point.lng()]);
  }

  return {
    name: "",
    iconId: "",
    geoJson: JSON.stringify(geoJson),
    isEnabled: true,
    uniqueId: uuidv4(),
  };
};

export const createGeozoneObjectForNewlyDrawedCircle = (
  circle: google.maps.Circle
) => {
  const center: google.maps.LatLng | null = circle.getCenter();
  const radius = circle.getRadius();
  const numSides = 64;

  const geoJSONCircle = generateGeoJSONCircle(
    center as google.maps.LatLng,
    radius,
    numSides
  );

  const geoJson = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      ...geoJSONCircle,
    },
    properties: {
      radius,
      centerPoint: [center?.lat(), center?.lng()],
    },
  };

  return {
    name: "",
    iconId: "",
    geoJson: JSON.stringify(geoJson),
    isEnabled: true,
    uniqueId: uuidv4(),
  };
};

export const generateGeoJSONCircle = (
  center: google.maps.LatLng | null,
  radius: number,
  numSides: number
) => {
  var points = [],
    degreeStep = 360 / numSides;

  for (var i = 0; i < numSides; i++) {
    var gpos = google.maps.geometry.spherical.computeOffset(
      center ? center : new google.maps.LatLng(0, 0),
      radius,
      degreeStep * i
    );
    points.push([gpos.lat(), gpos.lng()]);
  }

  points.push(points[0]);

  return {
    coordinates: points,
  };
};

export const transformGeoJSONCoordsToPaths = (
  coords: Array<Array<number>>
): Array<{ lat: number; lng: number }> => {
  try {
    return coords?.map((path) => ({ lat: path[0], lng: path[1] }));
  } catch (error) {
    return [DEFAULT_COORDINATES];
  }
};

function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

function radiansToDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}

export const getCenterCords = (
  objectRideLocations: any[],
  centerCoords: {
    lat: number;
    lng: number;
  }
) => {
  if (!objectRideLocations?.length) return centerCoords;

  let x = 0;
  let y = 0;
  let z = 0;

  objectRideLocations.forEach(({ latitude, longitude }) => {
    const latRad = degreesToRadians(latitude);
    const lngRad = degreesToRadians(longitude);

    x += Math.cos(latRad) * Math.cos(lngRad);
    y += Math.cos(latRad) * Math.sin(lngRad);
    z += Math.sin(latRad);
  });

  const totalPoints = objectRideLocations.length;

  x /= totalPoints;
  y /= totalPoints;
  z /= totalPoints;

  const lng = Math.atan2(y, x);
  const hyp = Math.sqrt(x * x + y * y);
  const lat = Math.atan2(z, hyp);

  return {
    lat: radiansToDegrees(lat),
    lng: radiansToDegrees(lng),
  };
};
