import { FC, Fragment, useState, useEffect, useRef } from "react";
import { PageLoader, types } from "@vilocnv/allsetra-core";
import { GoogleMap, PolylineF } from "@react-google-maps/api";

// Data
import { useMap } from "hooks";
import {
  getBoundsZoomLevel,
  getElementSize,
  getGroupedRides,
} from "app/data/helpers";
import { googleMapStyles } from "app/data/constants";
import { useTranslation } from "react-i18next";
import { MultipleRidesMapContainer } from "./MultipleRidesGoogleMap.styled";
import MultipleRidesMapMarkers from "./MultipleRidesMapMarkers";
import { IconBox, IconsWrapper, IconTooltip } from "../Map/Map.styled";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import { isEqual } from "lodash";

export interface MultipleRidesGoogleMapProps {
  objectRideLocations: Array<types.IObjectRideLocation>;
}

const MultipleRidesGoogleMap: FC<MultipleRidesGoogleMapProps> = ({
  objectRideLocations,
}) => {
  const { t } = useTranslation();
  const alarmContainerRef = useRef<HTMLDivElement>();
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [showSatelliteMode, setShowSatelliteMode] = useState<boolean>(false);
  const [showObjectName, setShowObjectName] = useState<boolean>(false);
  const [highlightedItem, setHighlightedItem] = useState<Array<any>>([]);

  const { mapRef, isLoaded, loadError, onMapLoad, centerCoords } = useMap({
    disableNavigator: true,
  });

  const handleMarkerClick = (
    item: types.IObjectRideLocation,
    mainItem: any,
    singleItem: any
  ) => {
    if (mainItem) {
      const paths = singleItem.map(
        (ride: { latitude: number; longitude: number }) => ({
          latitude: ride.latitude ?? 0,
          longitude: ride.longitude ?? 0,
        })
      );

      const highlightedPaths = singleItem.map(
        (ride: { latitude: number; longitude: number }) => ({
          lat: ride.latitude ?? 0,
          lng: ride.longitude ?? 0,
        })
      );

      const zoomLevel = getBoundsZoomLevel(
        paths,
        getElementSize(alarmContainerRef.current)
      );

      zoomLevel && setZoomLevel(zoomLevel - 1);

      //@ts-ignore
      const { latitude, longitude } = item;
      mapRef?.panTo({ lat: latitude, lng: longitude });

      setHighlightedItem(highlightedPaths);
    }
  };

  useEffect(() => {
    if (objectRideLocations.length > 0) {
      const allCoordinates = objectRideLocations.flatMap((item) =>
        //@ts-ignore
        item.items.map((ride) => ({
          latitude: ride.latitude ?? 0,
          longitude: ride.longitude ?? 0,
        }))
      );

      const mapDim = getElementSize(alarmContainerRef.current);
      const zoomLevel = getBoundsZoomLevel(allCoordinates, mapDim);

      setZoomLevel(zoomLevel || 11);

      if (isMapLoaded) {
        const bounds = new google.maps.LatLngBounds();
        allCoordinates.forEach((coord) => {
          bounds.extend({ lat: coord.latitude, lng: coord.longitude });
        });
        const center = bounds.getCenter();

        mapRef?.panTo(center);
        setHighlightedItem([]);
      }
    }
  }, [objectRideLocations, mapRef]);

  useEffect(() => {
    if (zoomLevel) setIsMapLoaded(true);
  }, [zoomLevel]);

  const handleZoomChanged = () => {
    //@ts-ignore
    if (mapRef?.zoom > 10) {
      setShowObjectName(true);
    } else {
      setShowObjectName(false);
    }
  };

  const renderUpdatedMarkers = (objects: Array<any>) => {
    if (!objectRideLocations.length) return null;

    const updatedMarkers = objectRideLocations.flatMap(
      (item: any, objectIndex: number) => {
        const groupedRides = getGroupedRides(item);

        return groupedRides.map((singleRide: Array<any>, rideIndex: number) => {
          return (
            <Fragment key={`marker-${singleRide[0].rideUniqueId}-${rideIndex}`}>
              {
                <MultipleRidesMapMarkers
                  singleItem={singleRide}
                  mainItem={item}
                  handleMarkerClick={handleMarkerClick}
                  showObjectName={showObjectName}
                />
              }
            </Fragment>
          );
        });
      }
    );

    return updatedMarkers;
  };

  const renderPolylineF = () => {
    if (!objectRideLocations.length) return null;

    const updatedRides = objectRideLocations.flatMap(
      (item: any, objectIndex: number) => {
        const groupedRides = getGroupedRides(item);

        return groupedRides.map((singleRide: Array<any>, rideIndex: number) => {
          const path = singleRide.map(
            (ride: { latitude: number; longitude: number }) => ({
              lat: ride.latitude ?? 0,
              lng: ride.longitude ?? 0,
            })
          );

          return (
            <PolylineF
              key={`${singleRide[0].rideUniqueId}-${objectIndex}-${rideIndex}`}
              path={path}
              options={{
                strokeColor: isEqual(path, highlightedItem)
                  ? "#845AFC"
                  : "#488EEB",
                strokeWeight: 6,
                zIndex: isEqual(path, highlightedItem) ? 2 : 1,
              }}
            />
          );
        });
      }
    );

    return updatedRides;
  };

  if (!isLoaded) {
    return <PageLoader />;
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isMapLoaded ? (
    <MultipleRidesMapContainer ref={alarmContainerRef}>
      <IconsWrapper>
        <IconTooltip title={t("common.showSatelliteMode")} arrow>
          <IconBox
            id="settelite-view"
            topMargin={false}
            active={showSatelliteMode}
            onClick={() => setShowSatelliteMode((prevState) => !prevState)}
          >
            {!showSatelliteMode ? (
              <SatelliteAltIcon color="secondary" fontSize="small" />
            ) : (
              <SatelliteAltIcon fontSize="small" />
            )}
          </IconBox>
        </IconTooltip>
      </IconsWrapper>
      <GoogleMap
        mapTypeId={
          showSatelliteMode
            ? google.maps.MapTypeId.HYBRID
            : google.maps.MapTypeId.ROADMAP
        }
        center={centerCoords}
        zoom={zoomLevel}
        onZoomChanged={handleZoomChanged}
        onLoad={onMapLoad}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{ styles: googleMapStyles }}
      >
        {renderUpdatedMarkers(objectRideLocations)}
        {renderPolylineF()}
      </GoogleMap>
    </MultipleRidesMapContainer>
  ) : (
    <PageLoader />
  );
};

export default MultipleRidesGoogleMap;
