import { FC } from "react";
import { IconBox, IconsWrapper, IconTooltip } from "../Map/Map.styled";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";

interface SatelliteModeIconProps {
  t: any;
  showSatelliteMode: boolean;
  setShowSatelliteMode: (value: any) => void;
}

const SatelliteModeIcon: FC<SatelliteModeIconProps> = ({
  t,
  showSatelliteMode,
  setShowSatelliteMode,
}) => {
  return (
    <IconsWrapper>
      <IconTooltip title={t("common.showSatelliteMode")} arrow>
        <IconBox
          id="settelite-view"
          active={showSatelliteMode}
          onClick={() =>
            setShowSatelliteMode((prevState: boolean) => !prevState)
          }
        >
          {!showSatelliteMode ? (
            <SatelliteAltIcon color="secondary" fontSize="small" />
          ) : (
            <SatelliteAltIcon fontSize="small" />
          )}
        </IconBox>
      </IconTooltip>
    </IconsWrapper>
  );
};

export default SatelliteModeIcon;
