import { FC } from "react";
import { Stack, useTheme } from "@mui/material";
import { FormikCheckbox, FormikInputField } from "@vilocnv/allsetra-core";
import {
  CheckBoxContainer,
  LabelLinkText,
  LabelText,
  PaymentFormContainer,
  TermsHeading,
} from "../../activationPortalForms.styled";
import { selectWhiteLabelsState } from "app/data/selectors";
import { useAppSelector } from "hooks";

interface Props {
  translator: any;
}

const InnerForm: FC<Props> = ({ translator }) => {
  const { whiteLabel } = useAppSelector(selectWhiteLabelsState);
  const theme = useTheme();

  const termsAndConditionsLink = whiteLabel
    ? whiteLabel.termsAndConditions
    : "https://allsetra.nl/wp-content/uploads/2022/06/Allsetra-2022-Website-Beelden-Algemene-Voorwaarden.pdf";

  const privacyPolicyLink = whiteLabel
    ? whiteLabel.privacy
    : "https://allsetra.nl/privacy-policy/";

  return (
    <PaymentFormContainer>
      <Stack spacing={3}>
        <h5>
          {translator("titles.paymentDetails", {
            ns: "activationPortal",
          })}
        </h5>
        <Stack spacing={3} direction={{ xs: "column", sm: "row" }}>
          <FormikInputField
            theme={theme}
            label={translator("accountHolderName.label", {
              ns: "formFieldsTranslation",
            })}
            name="accountHolderName"
            fullWidth
          />
          <FormikInputField
            theme={theme}
            label={translator("bankAccountNumber.label", {
              ns: "formFieldsTranslation",
            })}
            name="bankAccountNumber"
            fullWidth
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <TermsHeading>
          {translator("titles.termsAndConditions", {
            ns: "activationPortal",
          })}
        </TermsHeading>
        <Stack spacing={-1}>
          <FormikCheckbox
            name="authorizeAllsetra"
            label={translator("authorizeAllsetra.label", {
              ns: "formFieldsTranslation",
            })}
          />
          <FormikCheckbox
            name="directDebit"
            label={translator("directDebit.label", {
              ns: "formFieldsTranslation",
            })}
          />
          <CheckBoxContainer>
            <FormikCheckbox name="termsAndConditions" label={""} />
            <LabelText>
              {translator("titles.generalTermsStartingText", {
                ns: "activationPortal",
              })}{" "}
              <LabelLinkText href={termsAndConditionsLink} target="_blank">
                {translator("titles.generaltermsAndConditions", {
                  ns: "activationPortal",
                })}
              </LabelLinkText>{" "}
              {translator("titles.generalTermsEndingText", {
                ns: "activationPortal",
              })}
            </LabelText>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <FormikCheckbox name="privacyPolicy" label={""} />
            <LabelText>
              {translator("titles.privacyStartingText", {
                ns: "activationPortal",
              })}{" "}
              <LabelLinkText href={privacyPolicyLink} target="_blank">
                {translator("titles.privacyPolicy", {
                  ns: "activationPortal",
                })}
              </LabelLinkText>{" "}
              {translator("titles.privacyEndingText", {
                ns: "activationPortal",
              })}
            </LabelText>
          </CheckBoxContainer>
        </Stack>
      </Stack>
    </PaymentFormContainer>
  );
};

export default InnerForm;
