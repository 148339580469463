import { FC, useEffect, useMemo } from "react";
import {
  AccordionLayout,
  FormikSelectField,
  FormikInputField,
  Modal,
  types,
} from "@vilocnv/allsetra-core";
import { ModalProps, Stack, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { OBJECT_RIDE_MODES_OPTIONS } from "app/data/helpers";
import {
  getAccountPointsOfInterestsThunk,
  getAllKeysByAccountThunk,
} from "app/features";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectAccountKeysState,
  selectAccountPointOfInteretsState,
  selectDrawerSelectedAccountId,
} from "app/data/selectors";
import PlacesSearchField from "components/common/PlacesSearchField/PlacesSearchField";
import { useTranslation } from "react-i18next";

type Props = Omit<ModalProps, "title" | "children"> & {
  customIsSubmitting: boolean;
};

const InnerForm: FC<Props> = ({
  open,
  onClose,
  customIsSubmitting,
  ...rest
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    resetForm,
    isSubmitting,
    isValid,
    handleSubmit,
    setValues,
    values: formikValues,
  } = useFormikContext<types.IUpdateObjectRide>();

  // Global State
  const accountId = useAppSelector(selectDrawerSelectedAccountId);
  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );
  const { accountPointsOfInterests, accountPointsOfInterestsLoading } =
    useAppSelector(selectAccountPointOfInteretsState);

  const { t } = useTranslation(["translation"]);

  const rideModes = useMemo(() => OBJECT_RIDE_MODES_OPTIONS(t), [t]);

  useEffect(() => {
    dispatch(getAllKeysByAccountThunk(accountId ?? ""));
    dispatch(getAccountPointsOfInterestsThunk(accountId ?? ""));
  }, [accountId]);

  const onCloseHandler = () => {
    // @ts-ignore
    onClose();
    resetForm();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={"Edit ride"}
      primaryBtnProps={{
        type: "submit",
        text: "Save Changes",
        loading: isSubmitting || customIsSubmitting,
        disabled: !isValid,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: "Cancel",
        onClick: onCloseHandler,
      }}
      theme={theme}
      {...rest}
    >
      <Stack spacing={2}>
        <AccordionLayout title="General information">
          <Stack spacing={2}>
            <FormikSelectField
              theme={theme}
              label="Key"
              name="keyId"
              options={accountKeys ?? []}
              optionLabelKey={"label"}
              optionValueKey={"uniqueId"}
              loading={accountKeysLoading}
              fullWidth
            />
            <FormikSelectField
              theme={theme}
              label="Type"
              name="rideMode"
              options={rideModes ?? []}
              optionLabelKey={"label"}
              optionValueKey={"value"}
              required
              fullWidth
            />
            <FormikInputField
              theme={theme}
              label="Comment"
              name="comments"
              multiline
              rows={2}
              required
              fullWidth
            />
          </Stack>
        </AccordionLayout>
        <AccordionLayout title="Start of the ride">
          <Stack spacing={2}>
            <FormikSelectField
              theme={theme}
              label="Select your Address Label"
              name="startAddressPointOfInterest"
              options={accountPointsOfInterests ?? []}
              optionLabelKey={"address"}
              optionValueKey={"uniqueId"}
              loading={accountPointsOfInterestsLoading}
              fullWidth
            />
            <Typography variant={"body2"} sx={{ alignSelf: "center" }}>
              or use search with address
            </Typography>
            <PlacesSearchField
              label="Address"
              onLocationChange={(value) => {
                setValues({
                  ...formikValues,
                  correctedStartAddress: value.resolvedAddress,
                  correctedStartLatitude: value.lat,
                  correctedStartLongitude: value.long,
                });
              }}
              fullWidth
            />
          </Stack>
        </AccordionLayout>
        <AccordionLayout title="End of the ride">
          <Stack spacing={2}>
            <FormikSelectField
              theme={theme}
              label="Select your Address Label"
              name="endAddressPointOfInterest"
              options={accountPointsOfInterests ?? []}
              optionLabelKey={"address"}
              optionValueKey={"uniqueId"}
              loading={accountPointsOfInterestsLoading}
              fullWidth
            />
            <Typography variant={"body2"} sx={{ alignSelf: "center" }}>
              or use search with address
            </Typography>
            <PlacesSearchField
              label="Address"
              onLocationChange={(value) => {
                setValues({
                  ...formikValues,
                  correctedEndAddress: value.resolvedAddress,
                  correctedEndLatitude: value.lat,
                  correctedEndLongitude: value.long,
                });
              }}
              fullWidth
            />
          </Stack>
        </AccordionLayout>
      </Stack>
    </Modal>
  );
};

export default InnerForm;
