import React, { FC } from "react";
import { Grid, useTheme } from "@mui/material";
import { FormikInputField, FormikSelectField } from "@vilocnv/allsetra-core";
import { europeanCountryCodes } from "app/data/helpers";
import { LabelRequiedAsterisk, LabelText } from "./PhoneInput.styled";

interface Props {
  translator: any;
  phoneInputName: string;
  countryInputName: string;
  required?: boolean;
  label?: string;
}

const PhoneInput: FC<Props> = ({
  translator,
  phoneInputName,
  countryInputName,
  required = false,
  label = "",
}) => {
  const theme = useTheme();

  return (
    <Grid container alignItems={"stretch"} columnGap={2.375}>
      <Grid xs={12}>
        <LabelText>
          {label
            ? label
            : translator("phoneNumber.label", {
                ns: "formFieldsTranslation",
              })}
          {required && <LabelRequiedAsterisk>*</LabelRequiedAsterisk>}
        </LabelText>
      </Grid>
      <Grid item xs={3} pt={0.5}>
        <FormikSelectField
          theme={theme}
          name={countryInputName}
          options={europeanCountryCodes}
          optionValueKey={"value"}
          optionLabelKey={"value"}
          fullWidth
          required={required}
          searchable
        />
      </Grid>
      <Grid item xs={8.25}>
        <FormikInputField
          theme={theme}
          placeholder={
            translator
              ? translator("phoneNumber.placeholder", {
                  ns: "formFieldsTranslation",
                })
              : "Phone number"
          }
          name={phoneInputName}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default PhoneInput;
