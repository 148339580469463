import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
// @ts-ignore
import indexedDBStorage from "redux-persist-indexeddb-storage";
import rootReducer, { RootReducer } from "./features/rootReducer";
import rootRTKQuery from "./features/rootRTKQuery";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import * as Sentry from "@sentry/react";

// Create a Redux Persist configuration with IndexedDB storage
const persistConfig = {
  key: "root",
  storage: indexedDBStorage("myAppDB", "myAppStore"), // Database and object store names
  stateReconciler: autoMergeLevel2,
};

// Create a persisted reducer
const persistedReducer = persistReducer<RootReducer>(
  persistConfig,
  rootReducer
);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    rootReducer: persistedReducer,
    [rootRTKQuery.reducerPath]: rootRTKQuery.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootRTKQuery.middleware),
  enhancers: (defaultEnhancers) => [...defaultEnhancers, sentryReduxEnhancer],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
