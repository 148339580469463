import { FC } from "react";
import { IconButton, Stack, Box, useTheme, Typography } from "@mui/material";
import { Button, ButtonProps } from "@vilocnv/allsetra-core";
import {
  IconsWrapper,
  ToggleButton,
  ToggleIconsBox,
  TopbarContainer,
} from "./PlacesSearchTopbar.styled";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CustomPolygon, CustomCircular } from "assets/icons";
import ReplayIcon from "@mui/icons-material/Replay";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Data
import PlacesSearchField from "../PlacesSearchField/PlacesSearchField";

export interface Props {
  onLocationChange: (value: any) => void;
  onCloseClick: () => void;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  drawingShape?: string;
  setDrawingShape?: (value: string) => void;
  handleShapeChange?: (value: string) => void;
  handleResetShape?: () => void;
  circleSize?: number;
  setCircleSize?: (value: number) => void;
  creationMode: string | null;
}

const PlacesSearchTopbar: FC<Props> = ({
  onLocationChange,
  onCloseClick,
  primaryButton,
  secondaryButton,
  drawingShape,
  handleShapeChange,
  handleResetShape,
  circleSize,
  setCircleSize,
  creationMode,
}) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    if (setCircleSize) setCircleSize(Number(event.target.value));
  };

  return (
    <TopbarContainer>
      <Stack
        paddingX={2}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"100%"}
      >
        <IconButton id="close-button" onClick={onCloseClick}>
          <ArrowBackIosNewIcon color={"primary"} fontSize={"small"} />
          <Typography
            sx={{
              margin: "0px 10px",
              color: theme.palette.primary.main,
            }}
          >
            Editing
          </Typography>
        </IconButton>
        <Box
          sx={{ position: "relative", flex: 1, zIndex: 99999 }}
          mr={3}
          ml={2}
        >
          <PlacesSearchField onLocationChange={onLocationChange} fullWidth />
        </Box>
        <IconsWrapper>
          <ToggleIconsBox>
            <ToggleButton
              id="geozone-type-polygon"
              startIcon={<CustomPolygon />}
              disabled={creationMode !== "add"}
              disableRipple
              onClick={() =>
                handleShapeChange ? handleShapeChange("polygon") : false
              }
              sx={{
                backgroundColor:
                  drawingShape === "polygon" ? "white" : "transparent",
                fontWeight: drawingShape === "polygon" ? 700 : 400,
                border: "2px solid transparent",
              }}
            >
              Polygon
            </ToggleButton>
            <ToggleButton
              id="geozone-type-circular"
              startIcon={<CustomCircular />}
              sx={{
                backgroundColor:
                  drawingShape === "circle" ? "white" : "transparent",
                fontWeight: drawingShape === "circle" ? 700 : 400,
                border: "2px solid transparent",
              }}
              disabled={creationMode !== "add"}
              onClick={() =>
                handleShapeChange ? handleShapeChange("circle") : false
              }
            >
              Circular
            </ToggleButton>
          </ToggleIconsBox>
          {drawingShape === "circle" && (
            <Select
              id="circle-dropdown"
              variant={"standard"}
              value={circleSize?.toString()}
              label="Size"
              onChange={handleChange}
              sx={{ marginLeft: 2.5 }}
            >
              <MenuItem id="radius-100" value={100}>
                100 Meter
              </MenuItem>
              <MenuItem id="radius-250" value={250}>
                250 Meter
              </MenuItem>
              <MenuItem id="radius-500" value={500}>
                500 Meter
              </MenuItem>
              <MenuItem id="radius-1000" value={1000}>
                1 KM
              </MenuItem>
              <MenuItem id="radius-2000" value={2000}>
                2 KM
              </MenuItem>
              <MenuItem id="radius-5000" value={5000}>
                5 KM
              </MenuItem>
              <MenuItem id="radius-10000" value={10000}>
                10 KM
              </MenuItem>
              <MenuItem id="radius-25000" value={25000}>
                25 KM
              </MenuItem>
              <MenuItem id="radius-50000" value={50000}>
                50 KM
              </MenuItem>
            </Select>
          )}
          <IconButton
            id="geozone-reset"
            sx={{
              ml: "auto",
              border: "1px solid #DFE5F4",
              borderRadius: "4px",
              padding: "9.5px",
            }}
            onClick={handleResetShape ? handleResetShape : undefined}
          >
            <ReplayIcon
              fontSize="small"
              color="secondary"
              sx={{
                transform: "rotate(-90deg)",
              }}
            />
          </IconButton>
        </IconsWrapper>
        <Stack ml={2} gap={2} flexDirection={"row"} alignItems={"center"}>
          {secondaryButton && (
            <Button
              id="discard-button"
              theme={theme}
              size={"small"}
              {...secondaryButton}
            />
          )}
          {primaryButton && (
            <Button
              id="submit-button"
              theme={theme}
              size={"small"}
              {...primaryButton}
            />
          )}
        </Stack>
      </Stack>
    </TopbarContainer>
  );
};

export default PlacesSearchTopbar;
