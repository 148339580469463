import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

export function restructureData(data: any[]) {
  const tempData: any = {};

  if (!data?.length) return [];

  data.forEach(
    (item: { details: { date: string; data: any[] }[]; groupedInfo: any }) => {
      const { groupedInfo, details } = item;
      const { serialNumber } = groupedInfo;

      details?.forEach((detail) => {
        const detailedInfo = detail.data;

        detailedInfo?.forEach((ride: { start: string; end: string }) => {
          let start = new Date(ride.start);
          let end = new Date(ride.end);

          let rideCounted = false;

          while (start < end) {
            const currentDate = start.toISOString().split("T")[0];

            if (!tempData[serialNumber]) {
              tempData[serialNumber] = {
                serialNumber: serialNumber,
                name: groupedInfo.name,
                dataByDate: {},
                details,
                groupedInfo,
              };
            }

            if (!tempData[serialNumber].dataByDate[currentDate]) {
              tempData[serialNumber].dataByDate[currentDate] = {
                date: currentDate,
                rides: 0,
                hourDurations: Array.from({ length: 24 }, (_, index) => ({
                  hour: index,
                  duration: 0,
                })),
              };
            }

            const startHour = start.getHours();
            const nextHour = new Date(start);
            nextHour.setHours(startHour + 1, 0, 0, 0);

            const duration =
              Math.min(end.getTime(), nextHour.getTime()) - start.getTime();
            const durationInMinutes = Math.round(duration / (1000 * 60));

            const currentDuration =
              tempData[serialNumber].dataByDate[currentDate].hourDurations[
                startHour
              ].duration;

            tempData[serialNumber].dataByDate[currentDate].hourDurations[
              startHour
            ].duration = Math.min(currentDuration + durationInMinutes, 60);

            if (!rideCounted) {
              tempData[serialNumber].dataByDate[currentDate].rides += 1;
              rideCounted = true;
            }

            start = new Date(start.getTime() + duration);
          }
        });
      });
    }
  );

  return Object.keys(tempData).map((serialNumber) => ({
    serialNumber: tempData[serialNumber].serialNumber,
    name: tempData[serialNumber].name,
    dataByDate: Object.keys(tempData[serialNumber].dataByDate).map((date) => ({
      date: tempData[serialNumber].dataByDate[date].date,
      rides: tempData[serialNumber].dataByDate[date].rides,
      hourDurations: tempData[serialNumber].dataByDate[date].hourDurations,
    })),
    details: tempData[serialNumber].details,
    groupedInfo: tempData[serialNumber].groupedInfo,
  }));
}

export const localeDateOptions: {
  weekday: string;
  year: string;
  month: string;
  day: string;
} = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

function initializeSerialData(serialNumber: string, groupedInfo: any) {
  return {
    serialNumber,
    name: groupedInfo.name,
    dataByDate: {},
    details: [],
    groupedInfo,
  };
}

function initializeDateData(date: string) {
  return {
    date,
    rides: 0,
    hourDurations: Array.from({ length: 24 }, (_, index) => ({
      hour: index,
      workingHours: 0,
      ignitionHours: 0,
      ptoHours: 0,
    })),
  };
}

function calculateHour(rideTime: string, userTimeZone: string) {
  return parseInt(
    new Date(rideTime).toLocaleString("en-US", {
      hour: "2-digit",
      hour12: false,
      timeZone: userTimeZone,
    })
  );
}

function updateRideDurations(
  tempData: any,
  serialNumber: string,
  date: string,
  ride: { start: string; end: string; sessionType: number },
  startHour: number,
  endHour: number,
  durationInMinutes: number
) {
  const totalHoursCovered = durationInMinutes / 60;
  const startMinutes = new Date(ride.start).getMinutes();
  const endMinutes = new Date(ride.end).getMinutes();
  const startDuration = 60 - startMinutes;
  const endDuration = endMinutes;
  const startHourDuration =
    ((startDuration / 60) * durationInMinutes) / totalHoursCovered;
  const endHourDuration =
    ((endDuration / 60) * durationInMinutes) / totalHoursCovered;

  tempData[serialNumber].dataByDate[date].hourDurations[startHour] =
    updateWorkingHoursDuration(
      tempData[serialNumber].dataByDate[date].hourDurations[startHour],
      ride.sessionType,
      startHourDuration
    );

  tempData[serialNumber].dataByDate[date].hourDurations[endHour] =
    updateWorkingHoursDuration(
      tempData[serialNumber].dataByDate[date].hourDurations[endHour],
      ride.sessionType,
      endHourDuration
    );

  for (let hour = startHour + 1; hour < endHour; hour++) {
    tempData[serialNumber].dataByDate[date].hourDurations[hour % 24] =
      updateWorkingHoursDuration(
        tempData[serialNumber].dataByDate[date].hourDurations[hour % 24],
        ride.sessionType,
        ((60 / 60) * durationInMinutes) / totalHoursCovered
      );
  }
}

function processRide(
  tempData: any,
  serialNumber: string,
  date: string,
  ride: { start: string; end: string; sessionType: number },
  userTimeZone: string
) {
  const startHour = calculateHour(ride.start, userTimeZone);
  const endHour = calculateHour(ride.end, userTimeZone);
  const durationInMinutes =
    (new Date(ride.end).getTime() - new Date(ride.start).getTime()) /
    (1000 * 60);

  if (!isNaN(durationInMinutes)) {
    if (startHour === endHour) {
      tempData[serialNumber].dataByDate[date].hourDurations[startHour] =
        updateWorkingHoursDuration(
          tempData[serialNumber].dataByDate[date].hourDurations[startHour],
          ride.sessionType,
          durationInMinutes
        );
    } else {
      updateRideDurations(
        tempData,
        serialNumber,
        date,
        ride,
        startHour,
        endHour,
        durationInMinutes
      );
    }
  }
}

function processRideDetail(
  tempData: any,
  serialNumber: string,
  detail: { date: string; data: any[] }
) {
  const date = detail.date;
  if (!tempData[serialNumber].dataByDate[date]) {
    tempData[serialNumber].dataByDate[date] = initializeDateData(date);
  }

  detail.data.forEach(
    (ride: { start: string; end: string; sessionType: number }) => {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      processRide(tempData, serialNumber, date, ride, userTimeZone);
    }
  );

  tempData[serialNumber].dataByDate[date].rides += detail.data.length;
}

export function restructureWorkingHoursData(data: any[]) {
  const tempData: any = {};

  if (!data?.length) return [];

  data.forEach(
    (item: { details: { date: string; data: any[] }[]; groupedInfo: any }) => {
      const { groupedInfo, details } = item;
      const { serialNumber } = groupedInfo;

      if (!tempData[serialNumber]) {
        tempData[serialNumber] = initializeSerialData(
          serialNumber,
          groupedInfo
        );
      }

      details.forEach((detail) =>
        processRideDetail(tempData, serialNumber, detail)
      );
    }
  );

  return Object.keys(tempData).map((serialNumber) => ({
    serialNumber: tempData[serialNumber].serialNumber,
    name: tempData[serialNumber].name,
    dataByDate: Object.keys(tempData[serialNumber].dataByDate).map((date) => ({
      date: tempData[serialNumber].dataByDate[date].date,
      rides: tempData[serialNumber].dataByDate[date].rides,
      hourDurations: tempData[serialNumber].dataByDate[date].hourDurations,
    })),
    details: tempData[serialNumber].details,
    groupedInfo: tempData[serialNumber].groupedInfo,
  }));
}

function updateWorkingHoursDuration(
  hourDuration: any,
  sessionType: number,
  duration: number
) {
  switch (sessionType) {
    case 1:
      hourDuration.workingHours += Math.round(duration);
      break;
    case 2:
      hourDuration.ignitionHours += Math.round(duration);
      break;
    case 3:
      hourDuration.ptoHours += Math.round(duration);
      break;
    default:
      break;
  }
  return hourDuration;
}
