import axiosInstance from "app/axiosInstance";

class ReportsService {
  static generateReport = async (data: any) => {
    return await axiosInstance.post(`/reports`, {
      ...data,
      shouldNotResolveAddresses: true,
    });
  };

  static exportReport = async (data: any) => {
    return await axiosInstance.post(`/reports/export`, {
      ...data,
    });
  };
}

export default ReportsService;
