import axios from "axios";
import { AzureMsal } from "./data/services";
import { store } from "./store";
import { appInsights } from "./integrations/microsoftInsights";
import * as Sentry from "@sentry/react";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

instance.interceptors.request.use(
  async function (config) {
    config.headers["X-Subscription"] =
      process.env.REACT_APP_API_HEADER_SUBSCRIPTION;

    if (config.headers.isNotProtected) {
      return config;
    }

    try {
      const accessTokenResponse = await AzureMsal.acquireToken();
      const state = store.getState();

      if (accessTokenResponse) {
        config.headers[
          "Authorization"
        ] = `bearer ${accessTokenResponse.idToken}`;

        config.headers["X-TenantId"] =
          state.rootReducer.dashboardReducer.drawerSelectedAccountId;

        appInsights.setAuthenticatedUserContext(
          accessTokenResponse.account?.username || ""
        );
      }
    } catch (error) {
      console.log(error);
      AzureMsal.logoutOnTokenExp();
    } finally {
      return config;
    }
  },
  function (error) {
    // Log error to Sentry
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log error to Sentry
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export default instance;
