import * as Yup from "yup";

export const multipleRidesReportsFilterFormValidationSchema: Yup.Schema =
  Yup.object({
    objectType: Yup.array().of(Yup.string()).label("Object Types"),
    group: Yup.array().of(Yup.string()).label("Groups"),
    object: Yup.array()
      .of(Yup.string())
      .label("Objects")
      .required("At least one object must be selected")
      .min(1, "At least one object must be selected"),
    key: Yup.array().of(Yup.string()).label("Drivers"),
  });

export const getGroupedRides = (item: any) => {
  return item.items.reduce((acc: any[], ride: any) => {
    const lastGroup = acc[acc.length - 1];

    if (lastGroup && lastGroup[0].rideUniqueId === ride.rideUniqueId) {
      lastGroup.push(ride);
    } else {
      acc.push([ride]);
    }

    return acc;
  }, []);
};
