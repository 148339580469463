export function clearIndexedDB() {
    const dbName = "myAppDB"; // database name
  
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(dbName);
  
      request.onsuccess = () => {
        //@ts-ignore
        resolve();
      };
  
      request.onerror = (event) => {
        //@ts-ignore
        reject(event?.target?.errorCode || "");
      };
  
      request.onblocked = () => {
        console.warn("IndexedDB deletion blocked.");
      };
    });
  }
  