import { FC, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { KeyValueTable } from "@vilocnv/allsetra-core";
import AssignedUsersTable from "./children/AssignedUsersTable";
import ObjectDetailsCard from "components/cards/ObjectDetailsCard/ObjectDetailsCard";
import { ObjectDetailsLeftContainer } from "components/cards/ObjectDetailsCard/ObjectDetailsCard.styled";
import ObjectDetailsMap from "./children/ObjectDetailsMap";
import DevicesTable from "./children/DevicesTable";
import DataValuesAndNumber from "./children/DataValuesAndNumber";
import MileageCorrectionForm from "components/forms/objects/MileageCorrectionForm/MileageCorrectionForm";

// DATA
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import {
  transformObjectForObjectInfoTable,
  transformObjectForReminderTable,
  transformObjectForWorkingHoursTable,
  transformObjectForAlarmConfigTable,
  transformObjectForCorrectionTable,
  transformObjectOwnersDataForTable,
} from "app/data/helpers";
import { selectDrawerSelectedAccountId } from "app/data/selectors";

interface Props {
  specificObject: any | null;
  alarmsConfig: any;
}

const ObjectDetailsSection: FC<Props> = ({ specificObject, alarmsConfig }) => {
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation",
  ]);

  const { objectInformation, workingHours, alarmConfiguration, owners } =
    useMemo(() => {
      return {
        objectInformation: transformObjectForObjectInfoTable(specificObject),
        workingHours: transformObjectForWorkingHoursTable(specificObject),
        correction: transformObjectForCorrectionTable(specificObject, t),
        reminder: transformObjectForReminderTable(specificObject, t),
        alarmConfiguration: transformObjectForAlarmConfigTable(alarmsConfig),
        owners: transformObjectOwnersDataForTable(specificObject),
      };
    }, [specificObject, t]);

  return (
    <Box mt={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={5}>
          <ObjectDetailsLeftContainer spacing={4}>
            {specificObject && <ObjectDetailsCard object={specificObject} />}

            <KeyValueTable
              title={t("titles.location")}
              records={{
                [t("address.label", {
                  ns: "formFieldsTranslation",
                })]: `${specificObject?.location?.resolvedAddress || "N/A"}`,
              }}
            />

            <AssignedUsersTable specificObject={specificObject} />

            <KeyValueTable
              title={t("titles.objectInfo")}
              records={objectInformation}
            />

            <DataValuesAndNumber
              data={specificObject?.accounts ?? []}
              dataType="accounts"
            />

            <KeyValueTable records={owners} />

            <DataValuesAndNumber
              data={specificObject?.groups ?? []}
              dataType={t("drawerMenuLinks.groups")}
            />
            {specificObject?.isWorkingHoursOverriden ? (
              <KeyValueTable
                title={t("titles.workingHours")}
                records={workingHours}
                customizedWorkingHours={true}
              />
            ) : (
              <KeyValueTable
                title={t("titles.workingHours")}
                records={workingHours}
              />
            )}

            <MileageCorrectionForm
              specificObject={specificObject}
              accountId={drawerSelectedAccountId ?? ""}
            />

            <DevicesTable specificObject={specificObject} />

            <KeyValueTable
              title={t("titles.alarmConfig")}
              records={alarmConfiguration}
              customizedWorkingHours={
                alarmsConfig?.objectHasCustomAlarmConfiguration
              }
            />
          </ObjectDetailsLeftContainer>
        </Grid>

        <Grid item xs={12} lg={7} gap={2}>
          <ObjectDetailsMap activeObject={specificObject} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ObjectDetailsSection;
