import * as Yup from "yup";
import { convertISOToTime, convertToLocalTime } from "../reportCommonHelpers";

export const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const daysMapping = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

export const scheduleReportsFormInitialValues = {
  recipients: [],
  objectType: [],
  object: [],
  body: "",
  deliveryFrequencyType: 0,
  deliveryTime: "09:00:00",
  reportType: 2,
  key: [],
  startTime: "00:00",
  endTime: "23:59",
  exportType: 0,
  daysOfWeek: [1],
  assignedToAllObjects: true,
};

export const transformScheduleDataForForm = (specificSchedule: {
  reportDefinition: { startTime: string; endTime: string };
  assignedToAllObjects: boolean;
  deliveryTime: string;
}) => {
  const { reportDefinition, assignedToAllObjects, deliveryTime, ...rest } =
    specificSchedule;
  const { startTime, endTime } = reportDefinition;

  return {
    ...rest,
    ...reportDefinition,
    startTime: convertISOToTime(startTime),
    endTime: convertISOToTime(endTime),
    assignedToAllObjects: assignedToAllObjects ? assignedToAllObjects : true,
    deliveryTime: convertToLocalTime(deliveryTime),
  };
};

export const scheduleReportsFormValidationSchema: Yup.Schema = Yup.object({
  recipients: Yup.array().of(Yup.string()).min(1, "Value is Required"),
  reportType: Yup.number().required("Value is Required"),
  startTime: Yup.string().required("Value is Required"),
  deliveryFrequencyType: Yup.number().required("Value is Required"),
  exportType: Yup.number().required("Value is Required"),
});

export function getNextDeliveryTime(
  daysOfWeek: number[],
  deliveryTime: string,
  deliveryFrequencyType: number
): string {
  const currentDate = new Date();
  const [deliveryHours, deliveryMinutes] = deliveryTime.split(":").map(Number);

  let nextDeliveryDate = new Date(currentDate);

  switch (deliveryFrequencyType) {
    case 0:
      nextDeliveryDate = getNextDailyDelivery(
        nextDeliveryDate,
        deliveryHours,
        deliveryMinutes
      );
      break;
    case 1:
      nextDeliveryDate = getNextWeeklyDelivery(
        nextDeliveryDate,
        daysOfWeek,
        deliveryHours,
        deliveryMinutes
      );
      break;
    case 2:
      nextDeliveryDate = getNextMonthlyDelivery(
        nextDeliveryDate,
        daysOfWeek,
        deliveryHours,
        deliveryMinutes
      );
      break;
  }

  return formatDate(nextDeliveryDate)
    ? `Next: ${formatDate(nextDeliveryDate)}`
    : "-";
}

function getNextDailyDelivery(
  date: Date,
  hours: number,
  minutes: number
): Date {
  const nextDate = new Date(date);
  nextDate.setHours(hours, minutes, 0, 0);
  if (date >= nextDate) {
    return addDays(nextDate, 1);
  }
  return nextDate;
}

function getNextWeeklyDelivery(
  date: Date,
  daysOfWeek: number[],
  hours: number,
  minutes: number
): Date {
  const currentDay = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  const daysUntilNextDelivery = daysOfWeek
    ?.map((day) => (day - currentDay + 7) % 7)
    ?.sort((a, b) => a - b)[0];

  let nextDate = addDays(date, daysUntilNextDelivery);
  nextDate.setHours(hours, minutes, 0, 0);

  if (daysUntilNextDelivery === 0 && date.getHours() > hours) {
    nextDate = addDays(nextDate, 7);
  }

  return nextDate;
}

function getNextMonthlyDelivery(
  date: Date,
  daysOfMonth: number[],
  hours: number,
  minutes: number
): Date {
  const currentDay = date.getDate();
  let nextDayOfMonth =
    daysOfMonth?.find((day) => day > currentDay) || daysOfMonth[0];

  let nextDate;
  if (nextDayOfMonth <= currentDay) {
    nextDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      nextDayOfMonth,
      hours,
      minutes
    );
  } else {
    nextDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      nextDayOfMonth,
      hours,
      minutes
    );
  }

  return nextDate;
}

function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
}

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 24-hour format
  };
  return date.toLocaleString("en-US", options);
}
