import React, { Fragment, useMemo, memo } from "react";
import {
  MarkerClustererF,
  OverlayViewF,
  OverlayView,
} from "@react-google-maps/api";
import { MarkerBlip, MarkerLabel } from "../Map/Map.styled";
import ObjectMarker from "./ObjectMarker";

const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
};

const getPixelPositionOffset = () => ({
  x: 20,
  y: -29,
});

interface CustomMarkerClustererProps {
  map: google.maps.Map | undefined;
  markers: Array<any>;
  selectedMarkerLoading: boolean;
  showObjectNames: boolean;
  showClusters: boolean;
}

const MarkerClusterer: React.FC<CustomMarkerClustererProps> = ({
  map,
  markers,
  selectedMarkerLoading,
  showObjectNames,
  showClusters,
}) => {
  const visibleMarkers = useMemo(() => {
    //@ts-ignore
    if (!map || map.zoom <= 13) return markers;
    const mapBounds = map.getBounds();
    return markers.filter((marker) =>
      mapBounds?.contains(new window.google.maps.LatLng(marker.lat, marker.lng))
    );
  }, [map, markers]);

  const renderOverlay = (marker: any, clusterer: any, index: number) => {
    return (
      <Fragment key={`${index}`}>
        <ObjectMarker
          marker={marker}
          selectedMarkerLoading={selectedMarkerLoading}
          clusterer={clusterer}
        />
        {/* @ts-ignore */}
        {map?.zoom > 10 && (
          <OverlayViewF
            position={{ lat: marker.lat, lng: marker.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            {showObjectNames && (
              <Fragment>
                <MarkerLabel>{marker?.name || "N/A"}</MarkerLabel>
                <MarkerBlip></MarkerBlip>
              </Fragment>
            )}
          </OverlayViewF>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {showClusters && markers.length > 1 ? (
        <MarkerClustererF options={options} minimumClusterSize={5}>
          {(clusterer) => (
            <>
              {visibleMarkers &&
                visibleMarkers?.map((marker: any, i: number) =>
                  renderOverlay(marker, clusterer, i)
                )}
            </>
          )}
        </MarkerClustererF>
      ) : (
        visibleMarkers &&
        visibleMarkers?.map((marker: any, i: number) =>
          renderOverlay(marker, undefined, i)
        )
      )}
    </Fragment>
  );
};

export default memo(MarkerClusterer);
