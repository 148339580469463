import { FC } from "react";
import {
  ContentSectionLayout,
  TwoColsLayout,
  FormikInputField,
  Button,
} from "@vilocnv/allsetra-core";
import { Stack, useTheme } from "@mui/material";
import { ChildFormBox } from "./MileageCorrectionForm.styled";
import { useTranslation } from "react-i18next";

interface IProps {
  handleSubmit: () => void;
  resetForm: () => void;
  isValid: boolean;
  isSubmitting: boolean;
}

const InnerForm: FC<IProps> = ({
  handleSubmit,
  resetForm,
  isValid,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <ContentSectionLayout
      title={t("typography.mileageCorrection")}
      subTitle={t("typography.mileageCorrectionDescription")}
    >
      <ChildFormBox>
        <TwoColsLayout hideDivider>
          <FormikInputField
            theme={theme}
            id="new-mileage"
            disabled={isSubmitting}
            type="number"
            label={t("typography.newMileage")}
            name="newMileage"
            fullWidth
          />
          <FormikInputField
            theme={theme}
            id="correction-datetime"
            disabled={isSubmitting}
            label={t("typography.correctionDate")}
            type={"datetime-local"}
            name="correctionDate"
            fullWidth
          />
        </TwoColsLayout>
        <Stack mt={3} direction={"row"} spacing={2}>
          <Button
            id="submit-mileage-form"
            disabled={!isValid}
            onClick={handleSubmit}
            variant={"contained"}
            size={"small"}
            loading={isSubmitting}
          >
            {t("typography.addCorrection")}
          </Button>
          <Button
            id="reset-mileage-form"
            onClick={resetForm}
            variant={"text"}
            size={"small"}
          >
            {t("typography.cancel")}
          </Button>
        </Stack>
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default InnerForm;
