import { FC } from "react";
import ObjectDetailsSection from "components/sections/objects/ObjectDetailsSection/ObjectDetailsSection";
import ObjectTabsLayout from "components/common/objects/ObjectTabsLayout/ObjectTabsLayout";

// Data
import { useAppSelector } from "hooks";
import {
  selectActiveObjectAlarmsConfig,
  selectSpecificObjectState,
} from "app/data/selectors";

const ObjectDetails: FC = () => {
  // Global State
  const { specificObject } = useAppSelector(selectSpecificObjectState);
  const { alarmsConfig } = useAppSelector(selectActiveObjectAlarmsConfig);

  return (
    <main>
      <ObjectTabsLayout>
        <ObjectDetailsSection
          specificObject={specificObject}
          alarmsConfig={alarmsConfig}
        />
      </ObjectTabsLayout>
    </main>
  );
};

export default ObjectDetails;
